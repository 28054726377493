<script setup lang="ts">
// [stores]
import { useLangStore } from '@/stores/lang-store';
const langStore = useLangStore();
const t = langStore.t;
</script>
<template>
  <div class="">
    <div class="locale">
      <v-btn variant="outlined" color="white" @click="langStore.toggleLang()">
        {{ t('com.langtoggle') }}
      </v-btn>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.locale {
  z-index: 1;
  position: absolute;
  inset: 0 10px 0 auto;
  width: 12em;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 400 - 0.1}px) {
    width: 7em;
    font-size: 12px;
  }

  @media screen and (min-width: #{ 0 }px) and (max-width: #{ 600 - 0.1}px) {
    height: 50px;
  }
  > * {
    width: 100%;
  }
}
</style>
